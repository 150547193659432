import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";

import CouponForm from "~/components/Coupons/CouponForm";
import usePermissions from "~/hooks/usePermissions";
import couponsAPI from "~/utils/api/v2/coupons";

const CreateCoupon = () => {
  const { isCouponAdmin } = usePermissions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formRef = useRef();

  const submit = async () => {
    setIsLoading(true);
    const data = formRef.current?.getCouponData();
    const response = await couponsAPI.create({ data });

    if ("error" in response) {
      alert(JSON.stringify(response.error.response.data));
    } else {
      alert("Cupón creado exitosamente");
      setIsLoading(false);
      navigate("/coupons/");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isCouponAdmin) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <h1>Crear cupón</h1>
      <div className="bg-examedi-white-pure p-4 border rounded-md max-w-xl mx-auto my-4">
        <CouponForm ref={formRef} />
        <div className="flex flex-row justify-center mt-4 gap-4">
          <button
            className={clsx(
              "bg-examedi-blue-normal",
              "text-examedi-white-pure text-sm",
              "rounded-lg block p-2.5",
              isLoading && "bg-examedi-gray-bg cursor-wait",
            )}
            onClick={submit}
            disabled={isLoading}
          >
            CREAR
          </button>
          <button
            className="bg-examedi-gray-bg text-sm rounded-lg block p-2.5"
            onClick={() => {
              navigate("/coupons/");
            }}
          >
            VOLVER
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateCoupon;
